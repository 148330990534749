import React from 'react'
import Link from 'next/link'
import { observer } from 'mobx-react-lite'

import SidebarPromos from 'components/SidebarPromos'
import Sticky from 'components/Sticky'
import CCPALink from 'components/CCPALink'
import ClientOnly from 'components/ClientOnly'
import CaliforniaNoticesLink from 'components/CaliforniaNoticesLink'

import { THEME } from 'utils'
import { useStore } from 'hooks'
import ConfigStore from 'stores/config'
import HashtagLabsAd from 'components/HashtagLabsAd'
import { useRouter } from 'next/router'

const Sidebar = ({ adSettings = false, disableNativeAds = false, home = false }) => {
  const configStore = useStore(ConfigStore)
  const stickyEnabled = adSettings || adSettings.slot === 'home' || adSettings.slot === 'section'
  const router = useRouter()
  const VALID_PATHS = ['/', '/blog/[id]/[slug]']
  const validPath = VALID_PATHS.includes(router.pathname)
  const topGap = validPath ? '1000px' : '800px'
  const bottomGap = validPath ? '1500px' : '800px'

  return (
    <>
      <aside className='sidebar'>
        {configStore.current && <SidebarPromos items={configStore.current.sidebar_promos} />}
        {adSettings && (
          <>
            <div id='sidebar-section-1'>
              <ClientOnly>
                <Sticky height={topGap} enabled={stickyEnabled}>
                  <div className='sidebar__widget min-h-[500px]'>
                    <HashtagLabsAd id='sidebar-top' adSettings={adSettings} />
                  </div>
                  {!disableNativeAds && validPath && (
                    <div className='sidebar__widget'>
                      <div
                        id={home ? 'taboola-stream-5x1-thumbnails' : 'taboola-right-rail-thumbnails'}
                        className='taboolaContainer'
                      />
                    </div>
                  )}
                </Sticky>
              </ClientOnly>
            </div>
            <div id='sidebar-section-2'>
              <ClientOnly>
                <Sticky height={bottomGap} enabled={stickyEnabled}>
                  <div className='sidebar__widget'>
                    <HashtagLabsAd id='sidebar-middle' adSettings={adSettings} />
                  </div>
                  {!disableNativeAds && validPath && (
                    <div className='sidebar__widget'>
                      <div
                        id={home ? 'taboola-right-rail-home-thumbnails' : 'taboola-lower-rail-thumbnails'}
                        className='taboolaContainer'
                      />
                    </div>
                  )}
                </Sticky>
              </ClientOnly>
            </div>
            <div className='h-full'>
              <Sticky enabled={stickyEnabled}>
                <div className='sidebar__widget'>
                  <HashtagLabsAd id='sidebar-bottom' adSettings={adSettings} />
                </div>
                <div className='sidebar__legal'>
                  <p>
                    <Link href='/partnerships' legacyBehavior>
                      <a>Advertising Inquiries</a>
                    </Link>
                    <Link href='/terms-of-use' legacyBehavior>
                      <a>Terms of Use</a>
                    </Link>
                    <Link href='/privacy-policy' legacyBehavior>
                      <a>Privacy Policy</a>
                    </Link>
                    <Link href='/content-policy' legacyBehavior>
                      <a>Content Policy</a>
                    </Link>
                    <Link href='/messaging-terms' legacyBehavior>
                      <a>Messaging Terms</a>
                    </Link>
                    <CCPALink
                      style={{
                        fontSize: '0.75rem',
                        color: '#262626',
                        margin: '3px 8px'
                      }}
                    />
                    <CaliforniaNoticesLink
                      style={{
                        fontSize: '0.75rem',
                        color: '#262626',
                        margin: '3px 8px'
                      }}
                    />
                  </p>
                  <p className='rights'>&copy;{new Date().getFullYear()} Barstool Sports, Inc.</p>
                </div>
              </Sticky>
            </div>
          </>
        )}
      </aside>
      <style jsx>{`
        .sidebar {
          display: none;
          max-width: 300px;
          margin-left: auto;
          height: 100%;
        }
        .sidebar__widget {
          padding-bottom: 25px;
        }
        .sidebar__legal {
          margin: 0.5rem auto;
          padding: 1rem 0;
          text-align: center;
          border-top: 2px solid #ddd;
        }
        .sidebar__legal p {
          margin-bottom: 0;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          box-sizing: border-box;
        }
        .sidebar__legal a {
          font-size: 0.75rem;
          color: #262626;
          margin: 3px 8px;
        }
        .sidebar__legal .rights {
          margin-top: 0.25rem;
          font-size: 0.75rem;
          color: #949494;
        }

        @media (min-width: ${THEME.BREAKPOINTS.SM}px) {
          .sidebar {
            display: block;
            display: flex;
            flex-direction: column;
          }
        }
      `}</style>
    </>
  )
}

export default observer(Sidebar)
