import React from 'react'
import { THEME } from 'utils'
import { sidebarEvents } from 'lib/ga'
import Image from 'next/image'
import { track } from 'lib/whoami'

const SidebarPromos = ({ items }) => {
  if (!items || !items.length) return null

  const handleSidebarPromosLinkCDPEvent = (item) => {
    if (!item) return
    track('ContentClicked', {
      content: {
        src: `https://barstoolsports.com${item?.href}`,
        type: 'Link',
        componentName: 'SidebarPromos'
      }
    })
  }
  return (
    <div className='sidebarPromos'>
      {items.map((item, idx) => {
        if (!item.enabled) return null
        return (
          <a
            className='sidebarPromo'
            key={`sidebarPromo_${idx}`}
            target={item.external ? '_blank' : '_self'}
            href={item.href}
            rel='noreferrer'
            onClick={() => {
              sidebarEvents.featured(item.label, item.external)
              handleSidebarPromosLinkCDPEvent(item)
            }}
          >
            <Image className='sidebarPromo__image' src={item.img_url} alt={item.label} height={100} width={135} />
            <span className='sidebarPromo__text'>
              {item.label}
              <span className='sidebarPromo__link'>{item.link_text || 'Learn More'}</span>
            </span>
          </a>
        )
      })}
      <style jsx global>
        {`
          .sidebarPromo__image {
            display: block;
            height: 100px;
            width: auto;
          }
        `}
      </style>
      <style jsx>{`
        .sidebarPromo {
          display: flex;
          align-items: center;
          margin-bottom: 24px;
          background: white;
          box-shadow: ${THEME.BOX_SHADOW.DEFAULT};
        }
        .sidebarPromo__text {
          padding: 0.5rem 1rem;
          display: block;
          font-family: ${THEME.FONTS.HELVETICA};
          font-size: 0.875rem;
          font-weight: 700;
          color: ${THEME.COLORS.TEXTCOLOR};
        }
        .sidebarPromo__link {
          display: block;
          margin-top: 0.5rem;
          color: ${THEME.COLORS.LINKCOLOR};
          text-transform: uppercase;
        }
      `}</style>
    </div>
  )
}

export default SidebarPromos
