import React, { useState, useEffect } from 'react'
import Loading from 'icons/Loading'

const LoadingContainer = ({ isLoading, delay = 0, children = null }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    const timeout = isLoading && setTimeout(() => setVisible(true), delay)
    return () => {
      setVisible(false)
      clearTimeout(timeout)
    }
  }, [isLoading])

  if (!isLoading) {
    return children
  }

  if (!visible) {
    return null
  }

  return (
    <>
      <div className='loading-container'>
        <Loading />
      </div>
      <style jsx>
        {`
          .loading-container {
            display: flex;
            height: 100px;
            justify-content: center;
            align-items: center;
          }
        `}
      </style>
    </>
  )
}

export default LoadingContainer
