import React from 'react'

const Sticky = ({ enabled = true, height = '100%', top = 0, right = 0, left = 0, children }) => {
  return (
    <>
      <div className={`sticky-container ${enabled ? 'sticky-container--enabled' : 'sticky-container--disabled'}`}>
        <div className={`sticky ${enabled ? 'sticky--enabled' : 'sticky--disabled'}`}>{children}</div>
        {enabled && <div className='spacer' />}
      </div>
      <style jsx>{`
        .sticky-container--enabled {
          min-height: ${height};
        }
        .sticky--enabled {
          position: sticky;
          top: ${top}px;
          right: ${right}px;
          left: ${left}px;
        }
        .spacer {
          height: ${height};
        }
      `}</style>
    </>
  )
}

export default Sticky
